import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <p>{`Inputs come in two different colors. The default input color is `}<inlineCode parentName="p">{`$field-01`}</inlineCode>{` and is used on `}<inlineCode parentName="p">{`$ui-background`}</inlineCode>{` and `}<inlineCode parentName="p">{`$ui-02`}</inlineCode>{` page backgrounds. The `}<inlineCode parentName="p">{`--light`}</inlineCode>{` version input color is `}<inlineCode parentName="p">{`$field-02`}</inlineCode>{` and is used on `}<inlineCode parentName="p">{`$ui-01`}</inlineCode>{` page backgrounds.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--number input[type='number']`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$field-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--number--light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$field-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-04`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--number__controls`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$icon-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "32.74456521739131%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Number input example",
        "title": "Number input example",
        "src": "/static/e4886302fcfaa9a34766bc3d31c7d5fe/fb070/number-input-style-2.png",
        "srcSet": ["/static/e4886302fcfaa9a34766bc3d31c7d5fe/d6747/number-input-style-2.png 288w", "/static/e4886302fcfaa9a34766bc3d31c7d5fe/09548/number-input-style-2.png 576w", "/static/e4886302fcfaa9a34766bc3d31c7d5fe/fb070/number-input-style-2.png 1152w", "/static/e4886302fcfaa9a34766bc3d31c7d5fe/c3e47/number-input-style-2.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "interactive-states"
    }}>{`Interactive states`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--number:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$focus`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--number__controls:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$focus`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`[data-invalid]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`[data-invalid]:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--form-requirement`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--number:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--number input[type='number']:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-03`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Active:`}</strong>{` Number input should have a default number to start. The input should never be empty.`}</p>
    <p><strong parentName="p">{`Help text:`}</strong>{` Help text appears below the label when the input is active. Help text remains visible while the input is focused and disappears after focus away.`}</p>
    <p><strong parentName="p">{`Error:`}</strong>{` Error messages appear below the input field and are always present while invalid.`}</p>
    <p><strong parentName="p">{`Disabled:`}</strong>{` Disabled state should have a `}<inlineCode parentName="p">{`.not-allowed`}</inlineCode>{` cursor on hover.`}</p>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p>{`Number input labels should use sentence case, with only the first word in a phrase and any proper nouns capitalized.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 / 0.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$label-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--number input[type='number']`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--form-requirement`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 / 0.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$label-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <p>{`The height of a number input is always the same. The width however, varies based on content, layout and design. The caret icon can be found in the `}<a parentName="p" {...{
        "href": "/guidelines/iconography/library"
      }}>{`iconography`}</a>{` library.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--number input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 / 2.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--number input[type='number']`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--number__controls`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left, padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "32.74456521739131%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Structure and spacing for number input",
        "title": "Structure and spacing for number input",
        "src": "/static/b1591231db478bcdeaaf2c24c0d08221/fb070/number-input-style-1.png",
        "srcSet": ["/static/b1591231db478bcdeaaf2c24c0d08221/d6747/number-input-style-1.png 288w", "/static/b1591231db478bcdeaaf2c24c0d08221/09548/number-input-style-1.png 576w", "/static/b1591231db478bcdeaaf2c24c0d08221/fb070/number-input-style-1.png 1152w", "/static/b1591231db478bcdeaaf2c24c0d08221/c3e47/number-input-style-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      